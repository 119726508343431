<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        {{name}}
                        <v-spacer></v-spacer>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <add-button permission="exam-update"
                                        @action="form.dialog = true, editGrade=true, publishFlag=false">Assign
                                Grade
                            </add-button>
                        </transition>
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :hide-default-footer="form.items.data.length<11"
                            :footer-props="footerProps"
                            :options.sync="pagination"
                            :loading="form.loading"
                            class="elevation-1"
                    >

                        <template>
                            <tr>
                                <td class="text-xs-left">Grade</td>
                                <td class="text-xs-center">Evaluation</td>
                                <td class="text-xs-center">Marks Entry (Start/End)</td>
                                <td class="text-xs-center">Publish
                                    <!--<v-checkbox-->
                                    <!--v-if="$auth.can('exam-update')"-->
                                    <!--:input-value="props.all"-->
                                    <!--:indeterminate="props.indeterminate"-->
                                    <!--primary-->
                                    <!--hide-details-->
                                    <!--@click.stop="toggleAll"-->
                                    <!--class="checkboxFixed"-->
                                    <!--&gt;</v-checkbox>-->
                                </td>
                                <td class="text-xs-center">Action</td>

                            </tr>
                        </template>

                        <template v-slot:item="{index, item}">
                            <tr>
                                <td class="text-left">
                                    <strong>{{ item.name }}</strong> <br>
                                    <small style="color: #777">{{ item.coordinator ? item.coordinator : '-'
                                        }}
                                    </small>
                                </td>
                                <td class="text-center"><strong>{{ item.evaluation }}%
                                    <small style="color:#999;">of 100</small>
                                </strong></td>

                                <td class="text-center">
                                    <div v-if="item.details">
                                        <v-chip small label color="primary" text-color="white">
                                            {{ item.details.entry_duration.start }}
                                        </v-chip>
                                        <v-chip small label color="orange ml-2" text-color="white">
                                            {{ item.details.entry_duration.end }}
                                        </v-chip>
                                    </div>
                                </td>

                                <td class="text-center" v-if="item.is_publish === 1">
                                    <v-chip small label color="green"
                                            text-color="white">
                                        Published
                                    </v-chip>
                                </td>

                                <td v-else-if="item.details.entry_duration.start >= today" class="text-center">
                                    <v-chip small label color="default">
                                        Marks Entry Not started
                                    </v-chip>
                                </td>
                                <td v-else-if="(item.details.entry_duration.end >= today) && (item.details.entry_duration.start <= today) "
                                    class="text-center">
                                    <v-chip small label color="default">
                                        Marks Entry is on processing
                                    </v-chip>
                                </td>
                                <td v-else-if="(item.details.entry_duration.end < today) && $auth.can('exam-update')"
                                    class="text-center" :active="selected"
                                    style="cursor: pointer">
                                    <!--@click="selected.push(selected)"-->

                                    <v-checkbox
                                            style="display: inline-block"
                                            v-model="selected"
                                            danger
                                            :value="item.id"
                                            hide-details></v-checkbox>

                                    <!--<v-checkbox-->
                                    <!--@click="selected.indexOf(item)>-1?selected.slice(selected.indexOf(item),1): selected.push(item)"-->
                                    <!--:input-value="selected"-->
                                    <!--danger-->
                                    <!--:value="item"-->
                                    <!--hide-details></v-checkbox>-->
                                </td>
                                <td class="text-right">
                                    <edit-button v-if="item.is_publish !==1" permission="exam-update"
                                                 @agree="form.edit(item),editData(item),publishFlag=true"/>
                                    <delete-button v-if="item.is_publish !=1" permission="exam-delete"
                                                   @agree="form.delete(item.id)"/>
                                </td>

                            </tr>
                        </template>
                        <!--<template v-slot:body.append v-if="form.items.data.length">-->
                        <!--<tr>-->
                        <!--<td class="text-right" :colspan="headers.length">-->
                        <!--<br>-->
                        <!---->
                        <!--<br>-->
                        <!--</td>-->
                        <!--</tr>-->
                        <!--</template>-->
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                    <v-btn v-if="form.items.data.length" :disabled="selected.length<1" block large color="success"
                           @click="warningDialog=true">
                        Publish
                    </v-btn>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1 pa-3">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                lazy-validation>
                            <v-container grid-list-md pa-0>
                                <v-layout row wrap>
                                    <v-flex xs12 v-if="!editGrade">
                                        <v-select :items="grades" class="pa-0 pt-2" label="Grade" name="grade_id"
                                                  v-model="form.grade_id" :disabled="!editGrade"
                                                  :error-messages="form.errors.get('grade_id')" outlined dense/>
                                    </v-flex>
                                    <v-flex xs12 v-else>
                                        <v-select :error-messages="form.errors.get('grades')" v-model="form.grades"
                                                  :items="gradeLists" label="Select Grades" multiple
                                                  class="pt-0" :disabled="!editGrade" outlined dense>
                                            <v-list-item slot="prepend-item" ripple @click="toggle">
                                                <v-list-item-action>
                                                    <v-icon :color="form.grades.length > 0 ? 'indigo darken-4' : ''">{{
                                                        icon }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item>
                                            <v-divider slot="prepend-item" class="mt-2"></v-divider>
                                            <v-divider slot="append-item" class="mb-2"></v-divider>
                                        </v-select>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-autocomplete
                                                class="pa-0 pt-0"
                                                v-model="form.coordinator_id"
                                                :items="items"
                                                :error-messages="form.errors.get('coordinator_id')"
                                                :loading="isLoading"
                                                :search-input.sync="search"
                                                hide-no-data
                                                item-text="Name"
                                                item-value="id"
                                                label="Exam Co-ordinator"
                                                placeholder="Start typing to Search"
                                                return-objectn outlined dense/>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-calendar-field v-model="details.entry_duration.start" id-val="start"
                                                          label="Mark Entry Start*"></v-calendar-field>

                                        <!--                                        <v-text-field autocomplete="off" label="Mark Entry Start*" required class="pa-0"-->
                                        <!--                                                      :mask="'####-##-##'"-->
                                        <!--                                                      :rules="dateValidation"-->
                                        <!--                                                      v-model="details.entry_duration.start"-->
                                        <!--                                                      name="start"/>-->
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-calendar-field v-model="details.entry_duration.end" id-val="end"
                                                          label="Mark Entry End*"></v-calendar-field>
                                        <!--                                        <v-text-field autocomplete="off" label="Mark Entry End*" required class="pa-0"-->
                                        <!--                                                      :mask="'####-##-##'"-->
                                        <!--                                                      :rules="dateValidation"-->
                                        <!--                                                      v-model="details.entry_duration.end"-->
                                        <!--                                                      name="end"/>-->
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-text-field autocomplete="off" v-mask="'##'" label="Evaluation in(%)*"
                                                      required class="pa-0"
                                                      v-model="form.evaluation" name="evaluation"
                                                      :error-messages="form.errors.get('evaluation')" outlined dense/>
                                    </v-flex>
                                    <v-flex xs12>
                                        <small>*indicates required field</small>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" v-if="$auth.can('exam-update')" outlined @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="warningDialog" persistent max-width="400">
                <v-card>
                    <v-card-title class="title pa-3 warning white--text">
                        <v-icon class="mr-2 white--text">warning</v-icon>
                        Please, wait!
                    </v-card-title>
                    <v-card-text class="ma-0">Are you sure you modify results of selected class?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" outlined small @click="warningDialog = false">No</v-btn>
                        <v-btn color="warning" outlined small @click="publish">Yes</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {validateDate} from "../../../../library/helpers";

    const NepaliDate = require('nepali-date');
    const dt = new NepaliDate();

    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],

        data: () => ({
            isLoading: false,
            form: new Form({
                exam_id: '',
                grade_id: '',
                coordinator_id: '',
                evaluation: '',
                // is_publish: 0,
                details: {},
                grades: [],
            }, '/api/exam-grade'),
            warningDialog: false,
            search: '',
            publishFlag: false,
            grades: [],
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                itemsPerPage: 25,
            },
            details: {
                entry_duration: {
                    start: dt.format('YYYY-MM'),
                    end: dt.format('YYYY-MM'),
                },
                attendance_count_from: dt.format('YYYY-MM')
            },
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            today: dt.format('YYYY-MM-DD'),
            editGrade: true,
            name: '',
            coordinators: [],
            selected: [],
            is_final: false,
            assignedGrades: [],
            headers: [
                {text: 'Grade', align: 'left', value: 'name', width: 200},
                {text: 'Evaluation', align: 'center', value: 'name', sortable: true},
                {text: 'Marks Entry (Start/End)', align: 'center', value: 'name', sortable: false, width: 250},
                {text: 'Published', align: 'center', value: 'name', sortable: false},
                {text: 'Action', align: 'right', sortable: false}
            ],
            entries: [],
        }),
        computed: {
            ...mapState(['batch']),
            items() {
                return this.entries.map(entry => {
                    return Object.assign({}, entry, {Name: entry.name})
                })
            },
            selectAllGrades() {
                return this.form.grades.length === this.grades.length
            },
            selectFewGrades() {
                return this.form.grades.length > 0 && !this.selectAllGrades
            },
            icon() {
                if (this.selectAllGrades) return 'check_box';
                if (this.selectFewGrades) return 'indeterminate_check_box';
                return 'add_box'
            },
            gradeLists() {
                const $this = this;
                return this.grades.filter(item => {
                    return !$this.assignedGrades.includes(item.value);
                });
            }
        },
        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            search(val) {
                if (!val) return;
                if (this.isLoading) return;
                this.isLoading = true;
                this.$rest.get('api/user-search/slim?type=teacher&search=' + val)
                    .then(({data}) => {
                        this.entries = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            }
        },
        mounted() {
            this.getGrades();
        },

        methods: {

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString('examId=' + this.$route.params.examId) : params;
                this.form.get(null, query).then(({data}) => {
                    this.name = data.exam;
                    this.assignedGrades = data.data.map(function (grade) {
                        return grade.grade_id;

                    });
                    // this.pagination.totalItems = data.meta.total
                })
            },

            save() {
                this.form.exam_id = this.$route.params.examId;
                this.form.grades = this.form.grades.toString();
                this.form.details = this.details;
                if (this.form.is_publish) this.form.is_publish = 1;
                else this.form.is_publish = 0;
                this.details.entry_duration.end = this.details.entry_duration.end.dateForm();
                this.details.entry_duration.start = this.details.entry_duration.start.dateForm();
                this.form.store().then(data => {
                    this.details.entry_duration.end = dt.format('YYYY-MM-');
                    this.details.entry_duration.start = dt.format('YYYY-MM-');
                });
            },
            getGrades() {
                this.$rest.get('/api/grades?rowsPerPage=25&descending=false').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            editData(data) {
                if (data) this.details = data.details;
                if (!this.details.attendance_count_from) this.details.attendance_count_from = dt.format('YYYY-MM-')
                this.editGrade = false;
                this.entries = [];
                this.entries = [{
                    "name": data.coordinator,
                    "id": data.coordinator_id,
                    "Name": data.coordinator
                }]
            },
            gradeCordinator() {
                this.$rest.get('/api/member?type=exam-coordinator').then(({data}) => {
                    this.coordinators = data.data.map(res => {
                        return {value: res.id, text: res.name}
                    });
                })
            },
            toggle() {
                this.$nextTick(() => {
                    if (this.selectAllGrades) {
                        this.form.grades = []
                    } else {
                        this.form.grades = this.grades.map(grade => {
                            return grade.value;
                        })
                    }
                })
            },
            toggleAll() {
                if (this.selected.length) this.selected = [];
                else this.selected = this.form.items.data.slice()
            },
            changeSort(column) {
            },
            publish() {
                let examPublish = [];
                let selectedIds = [];
                this.selected.map(res => {
                    selectedIds.push(res);
                });
                this.form.items.data.map(res => {
                    if (selectedIds.includes(res.id)) {
                        examPublish.push({id: res.id, is_publish: 1, section_id:res.sections[0].id});
                    }

                });
                this.$rest.post('/api/exam-grade-publish', {examGrades: examPublish}).then(data => {
                    this.$events.fire('notification', {message: data.data.message, status: 'success'});
                    this.get();
                    this.warningDialog = false;
                }).catch(e => {
                    this.$events.fire('notification', {message: e.message, status: 'error'})
                });
            },
            getSelected() {
                this.selected = this.form.items.data.filter(res => {
                    if (res.is_publish == 1) {
                        return res;
                    }
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .checkboxFixed {
        position: absolute;
        margin-top: -22px;
    }
</style>